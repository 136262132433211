//* * this file should be modified only by you */
// import type { WritableComputedRef } from 'vue'
import type { CustomStoreImplementation, CustomStoreImplementationInstance } from 'kapix-components-vue3'
import type { StateTree, _GettersTree } from 'pinia'
import { useToast } from 'kapix-components-vue3'
import { formv3Hubspot } from '../../composables/hsFormSender'
import type { footerLayoutRaw } from './store'
import { HUBSPOT } from '~/constants/hubspot'
import { app } from '~/constants/app'

type FooterStore = ReturnType<typeof footerLayoutRaw>
function defineCustomStoreImplementation<S extends StateTree = {}, G extends _GettersTree<S> = {}, A = {}> (options: CustomStoreImplementation<FooterStore, S, G, A>) {
  return {
    instance: options as CustomStoreImplementationInstance<FooterStore, S, G, A>,
    options
  }
}

export default defineCustomStoreImplementation({
  state: () => {
    return {
      fields: [{ }]
    }
  },
  getters: {
    // // ** EXAMPLE ** //
    // myCustomGetter (): WritableComputedRef<Nullable<string>> {
    //   return computed({
    //     get: () => this.$title,
    //     set: (value) => {
    //       this.$title = value
    //     }
    //   })
    // }
  },
  actions: {
    async handleSubmitNewsLetter () {
      // Faire une vérif sur l'email puis faire un toast
      const toast = useToast()
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/gi
      if (this.saisieDeTexte.value && emailRegex.test(this.saisieDeTexte.value)) {
        const formGuidKey = HUBSPOT.FORMS_GUID_NEWSLETTER
        const obj = this.fields = [{
          value: this.saisieDeTexte.value || '', name: 'email'
        }]
        await formv3Hubspot(formGuidKey, obj)
        this.saisieDeTexte.value = ''
      }
      else toast.warning('Vous devez renseigner une adresse email valide')
    },
    // // ** EXAMPLE ** //
    myCustomAction () {
      // console.debug(this.$id)
    },
    beforeMount () {
    },
    async mounted () {
      this.$data.version = app.VERSION
    },
    beforeUnmount () {
    },
    unmounted () {
    }
  }
})
