import type { WritableComputedRef } from 'vue'
import type { CustomStoreImplementationOptions, KaElementMap, MergeCustomStore, StoreIdentifier } from 'kapix-components-vue3'
import { defineStore } from 'pinia'
import { initAliveStoreIds, kapixContext, navigateTo, openUrl, remove, scrollInfo } from 'kapix-components-vue3'
import footerLayoutStoreCustom from './store.custom'
import { $translate } from '~/modules/i18n'

const storeName = 'footerLayout'
const customImplement: CustomStoreImplementationOptions = footerLayoutStoreCustom.options
const aliveStoreIds = initAliveStoreIds()

function footerLayoutFactory (storeId?: Nullable<StoreIdentifier>) {
  return defineStore(storeId == null ? storeName : `${storeName}/${storeId}`, {
    state: () => {
      return {
        $aliveStoreIds: aliveStoreIds,
        $subStoreIds: 1,
        $storeId: storeId,
        $emit: (() => {}) as (propName: any, value: any) => void,
        $elements: {} as KaElementMap,
        $router: kapixContext.isClient ? kapixContext.$router : undefined,
        $route: kapixContext.isClient ? kapixContext.$route : undefined,
        // Page info,
        $title: $translate('footer.title', 'footer'),
        $description: $translate('footer.description', ''),
        $publishedTime: new Date('2023-02-13T07:42:17.521Z'),
        $modifiedTime: new Date('2023-06-05T10:37:05.717Z'),
        // Constants,
        $constants: {},
        // Data,
        $data: { version: '1.0.0' as Nullable<string> },
        $inputs: { saisieDeTexte: undefined as Nullable<string> },
        $info: { footer: scrollInfo() },
        ...(customImplement.state && customImplement.state())
      }
    },
    getters: {
      /* @ts-ignore: to allow override in your custom file */
      saisieDeTexte (): WritableComputedRef<Nullable<string>> {
        return computed({
          get: () => this.$inputs.saisieDeTexte,
          set: (value) => {
            this.$inputs.saisieDeTexte = value
          }
        })
      },
      ...customImplement.getters
    },
    actions: {
      /* @ts-ignore: to allow override in your custom file */
      async donneesPersonnellesClick () {
        await navigateTo({ name: 'donnees-personnelles' })
      },
      /* @ts-ignore: to allow override in your custom file */
      async conditionsGeneralesClick () {
        await navigateTo({ name: 'Cgv' })
      },
      /* @ts-ignore: to allow override in your custom file */
      async mentionsLegalesClick () {
        await navigateTo({ name: 'mentions-legales' })
      },
      /* @ts-ignore: to allow override in your custom file */
      icon2Click () {
        openUrl('https://www.linkedin.com/company/linkedin-com-company-matchers/', undefined)
      },
      /* @ts-ignore: to allow override in your custom file */
      icon3Click () {
        openUrl('https://www.facebook.com/matchersfr', undefined)
      },
      /* @ts-ignore: to allow override in your custom file */
      soumettreClick () {
        /* @ts-ignore: custom code */
        this.handleSubmitNewsLetter()
      },
      /* @ts-ignore: to allow override in your custom file */
      getStoreInstance (storeId?: Nullable<StoreIdentifier>) {
        return storeId != null ? getStoreInstance(storeId) : this
      },
      /* @ts-ignore: to allow override in your custom file */
      getStoreInstances () {
        return aliveStoreIds.map(storeId => this.getStoreInstance(storeId))
      },
      /* @ts-ignore: to allow override in your custom file */
      newStoreInstance (storeId?: Nullable<StoreIdentifier>) {
        const newStoreId = storeId || this.$subStoreIds++
        if (aliveStoreIds.includes(newStoreId)) {
          throw new Error(`Store with id ${storeId} already exists`)
        }
        aliveStoreIds.push(newStoreId)
        return getStoreInstance(newStoreId)
      },
      ...customImplement.actions,
      /* @ts-ignore: to allow override in your custom file */
      beforeMount () {
        customImplement.actions?.beforeMount?.call(this)
      },
      /* @ts-ignore: to allow override in your custom file */
      mounted () {
        customImplement.actions?.mounted?.call(this)
      },
      /* @ts-ignore: to allow override in your custom file */
      async serverPrefetch () {
        await customImplement.actions?.serverPrefetch?.call(this)
      },
      /* @ts-ignore: to allow override in your custom file */
      beforeUnmount () {
        customImplement.actions?.beforeUnmount?.call(this)
      },
      /* @ts-ignore: to allow override in your custom file */
      unmounted () {
        if (this.$storeId != null) {
          this.$dispose()
          remove(aliveStoreIds, this.$storeId)
        }
        customImplement.actions?.unmounted?.call(this)
      }
    }
  })
}

function getStoreInstance (storeId?: Nullable<StoreIdentifier>) {
  return footerLayoutFactory(storeId)()
}

export const footerLayoutRaw = footerLayoutFactory()
export const footerLayout = () => footerLayoutRaw() as MergeCustomStore<typeof footerLayoutStoreCustom.instance>
